import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css';
import './@core/css/row.css';
import './@core/css/bootstrap.min.css';
import './@core/css/swiper.min.css';
import './@core/css/all.min.css';
import './@core/css/select2.min.css';
import './@core/css/custome.css';
import './@core/css/myCss.css';
import './@core/css/elthwariAR.css';
import './@core/css/responsive.css';
import './@core/fontawesome/css/fontawesome.css';
import './@core/fontawesome/css/brands.css';
import './@core/fontawesome/css/solid.css';

Vue.use(Vuetify)

let lang = true;
if (window.localStorage.getItem('lang') !== undefined && window.localStorage.getItem('lang') != null && window.localStorage.getItem('lang') == 'en') {
    lang = false;
}
const opts = {
    rtl: lang,
}

export default new Vuetify(opts)