<template>
  <div>
  	<nav-bar></nav-bar>
      <router-view />
      <v-skeleton-loader v-if="$store.state.home.loadedContent == 0" 
          v-bind="{ class: 'mb-6', boilerplate: true, elevation: 2, }"
          type="image, card, card, card, card, card, card"
        ></v-skeleton-loader>
    <HomeFooter />
  
    <HomeModal/>
    <div class="loader-outer" v-if="$store.state.home.isLoading">
        <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
        </div>
    </div>
    <v-overlay
          :absolute="false"
          :value="$store.state.home.internetConnectionInterrupted"
        >
        <div class="alert alert-warning" role="alert">
          {{$t('gen.page_failed')}}
              <v-btn @click="ReloadPage">{{$t('gen.page_reload')}} </v-btn>
        </div>
    </v-overlay>
    <!-- <div style="position: absolute;">
      <v-alert
        @toggle= "$store.state.home.successAlertState = false"
        :value="$store.state.home.successAlertState"
        outlined
        dismissible
        :text:="$store.state.home.successAlertMessage"
        type="success"
      ></v-alert>
      <v-alert
        @toggle= "$store.state.home.errorAlertState = false"
        :value="$store.state.home.errorAlertState"
        outlined
        dismissible
        :text:="$store.state.home.errorAlertMessage"
        type="error"
      ></v-alert>
      <v-alert
        @toggle= "$store.state.home.warningAlertState = false"
        :value="$store.state.home.warningAlertState"
        outlined
        dismissible
        :text:="$store.state.home.warningAlertMessage"
        type="warning"
      ></v-alert>
      </div> -->
  </div>
	
	

</template>

<script>
import NavBar from './NavBar.vue'
// import HomeSlider from './blocks/HomeSlider.vue'
// import HomeSearch from './blocks/HomeSearch.vue'
// import HomeContent from './blocks/HomeContent.vue'
// import HomeBrand from './blocks/HomeBrand.vue'
import HomeFooter from './blocks/HomeFooter.vue'
import HomeModal from './blocks/Home/HomeModal.vue'
// import NavBar from '..//components/NavBar.vue'

export default {
  components: {
    // LayoutHorizontal,
    // HomeSlider,
    // HomeSearch,
    // HomeContent,
    // HomeBrand,
    HomeFooter,
    NavBar,
    HomeModal
},
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  methods: {
    ReloadPage(){
      location.reload();
    }
  },
}
</script>
<style>
.loader-outer{
  width: 100%;
    height: 100vh;
    background: #d4d4d491;
    /* opacity: .5; */
    display: flex;
    position: fixed;
    z-index: 99;
    top: 0;
    justify-content: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
    left: 50%;
    top: 50%;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid green;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: green transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>