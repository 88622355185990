import axios from "../../axios";

const state = {
    packages: [],
    baseUrl: 'https://api.althawri.com/api/v1',
};

const getters = {
    getPackages: state => state.packages,
};
const mutations = {
    setPackages(state, payload) {
        state.packages = payload;
    },
};
const actions = {
    fetchPackages({ commit }) {
        return axios.get(`/page/packages`)
            .then((response) => {
                commit("setPackages", response.data.data)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
};

export default {
    namespaced: true,
    name: "packages",
    state,
    getters,
    actions,
    mutations,
};