import axios from "../../axios";

const initToken = (() => {
    let authToken = window.localStorage.getItem('authToken') || null
    return authToken
})()


const state = {
    authToken: initToken,
    successModal: false,
    authName: null,
    profile: {},
    errors: [],
    loadingPage: 0,
    loginerrors: [],
    registererrors: [],
    profileerrors: [],
    baseUrl: 'https://api.althawri.com/api/v1',
};

const getters = {

    getErrors: state => state.errors,
    getLoginErrors: state => state.loginerrors,
    IsAuth(state) {
        return state.authToken != null ? true : false;
    }
};

const mutations = {
    setsuccessModal(state, payload) {
        state.successModal = payload;
    },
    setErrors(state, payload) {
        state.errors = payload;
    },
    setLoginErrors(state, payload) {
        state.loginerrors = payload;
    },
    setAuthToken(state, payload) {
        console.log(payload.token)
        state.authToken = payload.token;
        window.localStorage.setItem('authToken', payload.token)
        location.replace('/')
    },
    setAuthId(state, payload) {
        window.localStorage.setItem('auth', payload.id)
    },
    setAuthName(state, payload) {
        console.log(payload.name)
        state.authName = payload.name;
        window.localStorage.setItem('authName', payload.name)
    },
    makeLogout(state) {
        state.authToken = null;
        window.localStorage.removeItem('authToken')
        location.replace('/')
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    }



};
const actions = {
    login({ commit }, payload) {
        var body = {
            // email: payload.email,
            phone: payload.phone,
            country_data: payload.country_data,
            password: payload.password,
        }
        return axios.post(`/login`, body).then((response) => {
            console.log(response)
            if (response.data.code == 401) {
                commit("setLoginErrors", response.data.errors)
            } else if (response.data.code == 200) {
                commit("setAuthToken", { token: response.data.data.user.token })
                commit("setAuthId", { id: response.data.data.user.id })
                commit("setAuthName", { name: response.data.data.user.name })

            } else {
                commit("setLoginErrors", {
                    error: [response.data.errors.error]
                })
            }
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    sociallogin({ commit }, provider, body) {

        return axios.post(`/sociallogin/` + provider, body).then((response) => {
            console.log(response)
            commit("setLoginErrors", response.data.errors)
            commit("setAuthToken", { token: response.data.data.user.token })
            commit("setAuthId", { id: response.data.data.user.id })
            commit("setAuthName", { name: response.data.data.user.name })
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    signup({ commit }, payload) {
        var body = {
            name: payload.name,
            username: payload.username,
            // phone: payload.phone,
            phone: payload.phone.replace(/\s+/g, ''),
            is_valid_phone: payload.is_valid_phone,
            country_data: payload.country_data,
            email: payload.email,
            agree: payload.agree,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
        }
        return axios.post(`/signup-otp`, body).then((response) => {
            if (response.data.code == 401) {
                commit("setErrors", response.data.errors)

            } else if (response.data.code == 200) {
                // window.localStorage.setItem('successModal', '1');
                // commit("setAuthToken", { token: response.data.data.user.token })
                // commit("setAuthName", { name: response.data.data.user.name })

            } else {
                commit("setErrors", {
                    error: [response.data.errors.error]
                })
            }
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    logout({ commit }) {
        commit("makeLogout")
            // return axios.post(`/api/admin/logout`, body).then((response) => {
            //     console.log(response)
            //     commit("setAuthToken", {token: response.data.token})
            //     return response;
            // }).catch((error) => {
            //     console.log(error)
            // });
    },
};

export default {
    namespaced: true,
    name: "auth",
    state,
    getters,
    actions,
    mutations,
};