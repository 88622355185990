import axios from "../../axios";

const state = {
    auction: [],
    code: 200,
    message: '',
    cars: [],
    current: [],
    baseUrl: 'https://api.althawri.com/api/v1',
};

const getters = {
    getAuction: state => state.auction,
    getCars: state => state.cars,
    getCurrent: state => state.current,
};
const mutations = {
    setAuction(state, payload) {
        state.auction = payload;
    },
    setCars(state, payload) {
        state.cars = payload;
    },
    setCurrent(state, payload) {
        state.current = payload;
    },
    setCode(state, payload) {
        state.code = payload;
    },
    setMessage(state, payload) {
        state.message = payload;
    },
};
const actions = {
    fetchAuctionCurrent({ commit }, item) {
        return axios.get(`/page/auction_current/${item}`)
            .then((response) => {
                commit("setAuction", response.data.data.auction)
                commit("setCars", response.data.data.cars)
                commit("setCurrent", response.data.data.current)
                commit("setCode", response.data.code)
                commit("setMessage", response.data.message)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchAuction({ commit }, item) {
        return axios.get(`/page/auction/${item}`)
            .then((response) => {
                commit("setAuction", response.data.data.auction)
                commit("setCars", response.data.data.cars)
                commit("setCurrent", response.data.data.current)
                commit("setCode", response.data.code)
                commit("setMessage", response.data.message)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    bid({ commit }, payload) {
        var body = {
            product_id: payload.product_id,
            amount: payload.amount,
        }
        return axios.post(`/bid`, body).then((response) => {
            // console.log(response)
            // commit("setCurrent", response.data.data.car)
            commit("setCode", response.data.code)
            commit("setMessage", response.data.message)
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    clearDB({ commit }, payload) {
        var body = {}
        return axios.post(`/clear-auctions`, body).then((response) => {
            // console.log(response)
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    toggleFav({ commit }, payload) {
        var body = {
            product_id: payload.product_id,
            amount: payload.amount,
        }
        return axios.post(`/toggle-favourite`, body).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
};

export default {
    namespaced: true,
    name: "auc",
    state,
    getters,
    actions,
    mutations,
};