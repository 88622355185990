import axios from "../../axios";

const state = {
    counters: {
        making: 0,
        pending: 0,
        delivering: 0,
    },
    searchFilters: {},
    Paging: [],
    PagingPerPage: [],
    PagingCurrentPage: 0,
    PagingTotal: [],
    searchBrands: [],
    searchStyles: [],
    Products: [],
    homeLatestProducts: [],
    homeBrands: [],
    Auctions: [],
    searchYears: [],
    searchConditions: [],
    searchPrices: [],
    searchAttributes: [],
    searchBrandModels: [],
    filter: {},
    show_cars: '',
    baseUrl: 'https://api.althawri.com/api/v1',
};

const getters = {
    getCounters: state => state.counters,
    getPaging: state => state.Paging,
    getPagingPerPage: state => state.PagingPerPage,
    getPagingCurrentPage: state => state.PagingCurrentPage,
    getPagingTotal: state => state.PagingTotal,
    getSearchFilters: state => state.searchFilters,
    getAuctions: state => state.Auctions,
    getProducts: state => state.Products,
    getHomeLatestProducts: state => state.homeLatestProducts,
    getHomeBrands: state => state.homeBrands,
    getSearchBrands: state => state.searchBrands,
    getSearchStyles: state => state.searchStyles,
    getSearchYears: state => state.searchYears,
    getSearchConditions: state => state.searchConditions,
    getSearchPrices: state => state.searchPrices,
    getSearchAttributes: state => state.searchAttributes,
    getSearchBrandModels: state => state.searchBrandModels,
    getFilter: state => state.filter,
};
const mutations = {
    setCounters(state, payload) {
        state.counters = payload;
    },
    setPaging(state, payload) {
        let paging = [];
        if (payload.last_page > 1) {
            paging.push({
                text: 1,
                value: 1
            });
        }
        if (payload.last_page > 2) {
            paging.push({
                text: 2,
                value: 2
            });
        }
        if (payload.last_page > 3) {
            paging.push({
                text: 3,
                value: 3
            });
        }
        if (payload.last_page > 5) {
            paging.push({
                text: 'Next',
                value: -1
            });
        }
        if (payload.last_page > 1) {
            paging.push({
                text: payload.last_page,
                value: payload.last_page
            });
        }
        console.log('payload:', paging);
        state.Paging = paging;
        state.PagingPerPage = payload.to;
        state.PagingCurrentPage = payload.current_page;
        state.PagingTotal = payload.total;
    },
    setSearchFilters(state, payload) {
        state.searchFilters = payload;
    },
    setProducts(state, payload) {
        state.Products = payload;
    },
    setHomeLatestProducts(state, payload) {
        state.homeLatestProducts = payload;
    },
    setHomeBrands(state, payload) {
        state.homeBrands = payload;
    },
    setAuctions(state, payload) {
        state.Auctions = payload;
    },
    setSearchBrands(state, payload) {
        state.searchBrands = payload;
    },
    setSearchStyles(state, payload) {
        state.searchStyles = payload;
    },
    setSearchYears(state, payload) {
        state.searchYears = payload;
    },
    setSearchConditions(state, payload) {
        state.searchConditions = payload;
    },
    setSearchPrices(state, payload) {
        state.searchPrices = payload;
    },
    setSearchAttributes(state, payload) {
        state.searchAttributes = payload;
    },
    setSearchBrandModels(state, payload) {
        state.searchBrandModels = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
};
const actions = {
    fetchCounters({ commit }) {
        return axios.get(`/api/admin/orders/orders-counter`)
            .then((response) => {
                commit("setCounters", response.data)
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchSearchFilters({ commit }) {
        return axios.get(`/search-filters`)
            .then((response) => {
                commit("setSearchFilters", response.data)
                commit("setSearchBrands", response.data.data.brands)
                commit("setSearchStyles", response.data.data.styles)
                commit("setSearchYears", response.data.data.years)
                commit("setSearchConditions", response.data.data.conditions)
                commit("setSearchPrices", response.data.data.prices)
                commit("setSearchAttributes", response.data.data.attributes)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchProducts({ commit }, query) {

        // this.state.auth.loadingPage = 1;
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        commit("setFilter", query)
        return axios.get(`/page/filter`, { params: state.filter })
            .then((response) => {
                commit("setProducts", response.data.data.products.data)
                commit("setAuctions", response.data.data.auctions)
                commit("setPaging", response.data.data.products)
                this.state.auth.loadingPage = 0;
                return response;
            }).catch((error) => {
                this.state.auth.loadingPage = 0;
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
};

export default {
    namespaced: true,
    name: "filter",
    state,
    getters,
    actions,
    mutations,
};