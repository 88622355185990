<template>
    <AppLayout/>
    <!-- f -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import AppLayout from './components/AppLayout.vue'
  import axios from "./axios";
  import store from '@/store'

export default {
  name: 'App',
  components: {
    AppLayout
  },
  data(){
    return {
      inactiveTime: 0,
      inactiveThreshold: 3 * 60 * 1000
       // 5 minutes in milliseconds
    };
  },
  methods: {
    loadData() {
        // console.log("Object.keys(this.$store.state.home.appInfo).length", Object.keys(this.$store.state.home.appInfo).length);
          if(Object.keys(this.$store.state.home.appInfo).length == 0){
                  axios.get(`/app-info`, {}).then((response) => {
          this.$store.state.home.loadedContent = 1;
                      this.$store.state.home.appInfo =  response.data.data.info;
                      // console.log("this.$store.state.home.appInfo", this.$store.state.home.appInfo);
                      // this.$route.meta.title =  this.$store.state.home.appInfo.site_name_ar;
                      //   document.title = this.$route.meta.title;
                      if(this.$route.meta.pageTitle !== undefined){
                        this.$route.meta.title =  this.$store.state.home.appInfo.site_name_ar + " | " + this.$route.meta.pageTitle;
                        
                      }else{
                        this.$route.meta.title =  this.$store.state.home.appInfo.site_name_ar ;

                      }
                      document.title = this.$route.meta.title;
                    }).catch((error) => {
                      console.log(error)
                  });
          }
        },
    checkInternetConnection() {
        if (!navigator.onLine) {
            this.$store.state.home.internetConnectionInterrupted = true;
            // Your code for when the internet connection is not available
        }
    },
    resetInactiveTime() {
      this.checkForInactivity();
      this.inactiveTime = 0;
    },

    // Function to handle user inactivity
    handleInactive() {
        // This function will be called when the user is inactive for more than 5 minutes
        // console.log("User has been inactive for more than 5 minutes.");
        this.$store.state.home.internetConnectionInterrupted = true;
        // You can add your own code here to handle the inactive state, like displaying a message or logging the user out
    },
    // Function to check for inactivity
    checkForInactivity() {
        this.inactiveTime += 3050; // Check every second
        // console.log("this.inactiveTime", this.inactiveTime);
        if (this.inactiveTime >= this.inactiveThreshold) {
            // this.handleInactive();
        }
    },
    ServerTime() {
      fetch('https://api.althawri.com/api/server-time2')
      .then(response => response.json()).then(data => {
        this.$store.state.home.current_server_time = data.server_time;
      }).catch(err => {
          console.log({err:err})
      });
    }
  },
  mounted() {
    
    if(window.localStorage.getItem('lang') !== undefined && window.localStorage.getItem('lang') != null){
        let h = document.querySelector('html');
        h.setAttribute('lang', window.localStorage.getItem('lang'));
        this.$store.state.home.htmlLang = window.localStorage.getItem('lang');
			this.$i18n.locale = window.localStorage.getItem('lang');
      }else{
        window.localStorage.setItem('lang', 'ar');
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
        this.$store.state.home.htmlLang = window.localStorage.getItem('lang');
			this.$i18n.locale = window.localStorage.getItem('lang');
      }
    setInterval(() => {
      this.ServerTime();
    }, 50500);
    window.Echo.channel(`server-time` )
    .listen('ServerTimeEvent', (e) => {
      this.$store.state.home.current_server_time = e.data.server_time;
    });
    // Event listener for user activity
    document.addEventListener('mousemove', () => {
        this.resetInactiveTime();
    });

    document.addEventListener('keypress', () => {
        this.resetInactiveTime();
    });
    // Check if the Page Visibility API is supported
    if (typeof document.hidden !== "undefined") {
        // Add event listeners for visibility change
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                // Tab is inactive
                // console.log("Tab is inactive");
                this.checkForInactivity();

                // Add your code here to handle when the tab becomes inactive
            } else {
                // Tab is active
                // console.log("Tab is active");
                // location.reload();
                this.$store.state.home.should_reload = new Date().getTime();
                this.checkForInactivity();
                // Add your code here to handle when the tab becomes active again
            }
        });
    } else {
        console.log("Page Visibility API is not supported");
        // Fallback to other methods if Page Visibility API is not supported
    }
    setInterval(() => {
      this.checkForInactivity();
      this.checkInternetConnection();
      if(!window.Echo.connector.socket.connected) {
          this.$store.state.home.internetConnectionInterrupted = true;
          // Handle the error as needed
      }
    }, 3050);
    window.onerror = (message, source, lineno, colno, error) => {
      // alert("Error: " + message);
      // this.$toast.open({
      //                   message: "Error: " + message,
      //                   type: 'error',
      //                   // all of other options may go here
      //               });
  };
    window.addEventListener("error", (evt) => {
                if (evt.message) { // Chrome sometimes provides this
                  // this.$toast.open({
                  //       message: "error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename,
                  //       type: 'error',
                  //       // all of other options may go here
                  //   });
                } else {
                  // this.$toast.open({
                  //       message: "error: "+evt.type+" from element: "+(evt.srcElement || evt.target),
                  //       type: 'error',
                  //       // all of other options may go here
                  //   });
                }
            }, true);

      this.loadData();
      Notification.requestPermission();
      if(window.localStorage.getItem('lang') !== undefined && window.localStorage.getItem('lang') != null){
        let h = document.querySelector('html');
        h.setAttribute('lang', window.localStorage.getItem('lang'));
      }else{
        window.localStorage.setItem('lang', 'ar');
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
      }
    //  let token = window.localStorage.getItem('authToken') || null;
  },
  watch: {
    "$route":{
      handler(val){
        this.loadData();
      },
      deep:true,
    }
  },
}
</script>

<style>
@import '@core/css/bootstrap.min.css';
@import '@core/css/swiper.min.css';
@import '@core/css/all.min.css';
@import '@core/css/select2.min.css';
@import '@core/css/custome.css';
@import '@core/css/myCss.css?v=3';
@import '@core/css/elthwariAR.css';
@import '@core/css/responsive.css';
@import '@core/css/ltr.css?v=2';
@import '@core/css/responsiveLtr.css';
@import '@core/fontawesome/css/fontawesome.css';
@import '@core/fontawesome/css/brands.css';
@import '@core/fontawesome/css/solid.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@font-face {
    font-family: sitefont;
    src: url("./@core/fonts/DINNextLTArabic-Regular-4.ttf") format("truetype");
}

@font-face {
    font-family: sitefont;
    font-weight: 500;
    src: url("./@core/fonts/DINNextLTArabic-Medium-4.ttf") format("truetype");
}

@font-face {
    font-family: sitefont;
    font-weight: bold;
    src: url("./@core/fonts/DINNextLTArabic-Bold-4.ttf") format("truetype");
}

* {
    font-family: 'sitefont', 'Tajawal', sans-serif !important;
}
.container{
  padding:0 15px!important;
}
.navbar-nav{
  padding-right:40px;
}
#app {
    -webkit-font-smoothing: unset !important;
    -moz-osx-font-smoothing: unset !important;
    text-align: unset !important;
    color: unset !important;
    margin-top: unset !important;
}
.theme--light.v-application {
    background: unset !important;
    color: rgba(0,0,0,.87);
}
.v-text-field.v-text-field--solo .v-input__control{
    min-height: 10px;
}

.v-label{
  font-size: 10px;
}
.v-text-field.v-text-field--solo .v-input__control {
    min-height: 37px !important;
    padding: 0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: 0 0px 0px 0px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 2px 0 rgba(0,0,0,.12) !important;
    background: #fbfbfb;
}
.v-list-item.v-list-item--link.theme--light:hover,
.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light.v-list-item--highlighted{
  background:#6360aa !important;
  color:#fff !important;
}
.v-select__selection--comma {
    height: 20px;
}
.fa, .far, .fas {
    font-family: "Font Awesome 5 Free"!important;
}
.fa, .fas {
    font-family: 'Font Awesome 5 Free'!important;
    font-weight: 900;
}
.fab {
    font-family: 'Font Awesome 5 Brands'!important;
    font-weight: 400;
}
/* .row {
    margin: unset !important;
} */
.v-toast.v-toast--bottom{
  z-index: 999999;
}
.pagination-btm {
  margin: 0px 10px
}
.pagination-btm .page-item .page-link {
    margin: 3px;
    padding: 5px 9px !important;
    border-radius: .2rem !important;
}
.pagination-btm .page-item:first-child .page-link {
    /* margin-left: 0; */
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    /* border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; */
}
.pagination-btm .page-item:last-child .page-link {
    /* margin-left: 0; */
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
</style>
