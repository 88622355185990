import axios from "../../axios";

const state = {
    ItemsList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://api.althawri.com/',
    CategoriesList: [],
    fav: "",
};

const getters = {
    getItemsList: state => state.ItemsList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,
    getCategoriesList: state => state.CategoriesList,
    getFav: state => state.fav,

};
const mutations = {
    setItemsList(state, payload) {
        state.ItemsList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    setCategoriesList(state, payload) {
        state.CategoriesList = payload;
    },
    setFav(state, payload) {
        state.fav = payload;
    },
    setUnFav(state, payload) {
        state.fav = payload;
    }



};
const actions = {
    fetchItemsList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/items`, { params: state.filter })
            .then((response) => {
                commit("setItemsList", response.data.data)
                commit("setLastPage", response.data.last_page);
                console.log("itemssss", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postItem
    postItem({ commit }, item) {

        let formData = new FormData();
        formData.append("name", item.name);
        formData.append("price", item.price);
        formData.append("image", item.image);
        formData.append("nameAr", item.nameAr);
        formData.append("category_id", item.category_id);
        return axios.post(`/api/items`, formData).then((response) => {
            alert("تم اضافة المنتج بنجاح");
            commit("addItem", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                alert(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updateItem
    updateItem({ commit }, item) {
        let image = (`https://api.althawri.com/`, item.image); //concat the image url
        let formData = new FormData();
        formData.append("name", item.name);
        formData.append("price", item.price);
        formData.append("image", image);
        formData.append("nameAr", item.nameAr);
        formData.append("category_id", item.category_id);
        console.log("image out ", image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/items/${item.id}?_method=PUT`, formData).then((response) => {
            commit("setItem", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            alert("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deleteItem({ commit }, id) {
        return axios.delete(`/api/items/${id}`).then((response) => {
            commit("deleteItem", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },
    fetchCategoriesDropdown({ commit }) {
        return axios.get(`/api/admin/categories/list`)
            .then((response) => {
                commit("setCategoriesList", response.data)
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },


    toggleFavourite({ commit }, item) {
        return axios.put(`/api/admin/items/specialize/${item}`)
            .then((response) => {
                commit("setFav", response.data)
                console.log(response);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },
    unToggleFavourite({ commit }, item) {
        return axios.put(`/api/admin/items/unspecialize/${item}`)
            .then((response) => {
                commit("setUnFav", response.data)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    }


};

export default {
    namespaced: true,
    name: "items",
    state,
    getters,
    actions,
    mutations,
};