import axios from "../../axios";

const state = {
    CategoriesList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://api.althawri.com/',
};

const getters = {
    getCategoriesList: state => state.CategoriesList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    setCategoriesList(state, payload) {
        state.CategoriesList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addItem(state, payload) {
        state.Categories = payload;
    },



};
const actions = {
    fetchCategoriesList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/categories`, { params: state.filter })
            .then((response) => {
                commit("setCategoriesList", response.data.data)
                commit("setLastPage", response.data.last_page);
                console.log("imagess", response.data.data[0].images[0]);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postItem
    postItem({ commit }, item) {

        let formData = new FormData();
        formData.append("name", item.name);
        for (let i = 0; i < item.images.length; i++) {
            formData.append("images[]", item.images[i])
        }
        formData.append("image", item.image);
        formData.append("nameAr", item.nameAr);
        return axios.post(`/api/admin/categories`, formData).then((response) => {
            alert("تم اضافة المنتج بنجاح");
            commit("addItem", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                alert(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updateItem
    updateItem({ commit }, item) {
        let image = (`https://api.althawri.com/`, item.image); //concat the image url
        let formData = new FormData();
        formData.append("name", item.name);
        formData.append("image", image);
        formData.append("nameAr", item.nameAr);
        console.log("image out ", image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/categories/${item.id}?_method=PUT`, formData).then((response) => {
            commit("setItem", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            alert("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deleteCategory({ commit }, id) {
        return axios.delete(`/api/admin/categories/${id}`).then((response) => {
            commit("deleteCategory", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });

    }


};

export default {
    namespaced: true,
    name: "categories",
    state,
    getters,
    actions,
    mutations,
};