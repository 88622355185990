import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import axios from "./axios";
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'
import LoadScript from 'vue-plugin-load-script';
import i18n from "./i18n";

Vue.use(LoadScript);
Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {

    providers: {
        google: {
            clientId: '248155709936-n2ech169rfets4nemk0vdfugboqj6e39.apps.googleusercontent.com',
            redirectUri: 'https://althawri.com/provider/google/callback' // Your client app URL
        },
        facebook: {
            clientId: '231378162867890',
            redirectUri: 'https://althawri.com/provider/facebook/callback'
        }
    }
})
import "bootstrap";
import Select2 from 'v-select2-component';
import 'vue-select/dist/vue-select.css';
// import VueTelInput from 'vue-tel-input-fix'
import VueTelInput from "vue-tel-input";
// import 'vue-tel-input-fix/dist/vue-tel-input.css'; 
// import 'vue-tel-input/dist/vue-tel-input.css';
// import FlagIcon from 'vue-flag-icon'

import vuetify from './vuetify'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
//Vue.$toast.open({/* options */});

import VueMoment from 'vue-moment'

Vue.use(VueMoment)
import VueApexCharts from 'vue-apexcharts'
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueLazyload from 'vue-lazyload'
// const loadimage = require('./assets/loading.gif')
// const errorimage = require('./assets/error.gif')

// Vue.use(VueLazyload, {
//     preLoad: 1.3,
//     error: errorimage,
//     loading: loadimage,
//     attempt: 1
// })
import Echo from "laravel-echo"
window.io = require("socket.io-client");
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: 'https://api.althawri.com:6001',
    transports: ['websocket'],
    secure: true,
});
window.Echo.connector.socket.on('error', function(error) {
    console.error('Socket connection error:', error);
    // Handle the error as needed
});
window.Echo.channel(`notification`)
    .listen('NotificationEvent', (e) => {
        // alert(1);
        // console.log(e)
    });
window.Echo.channel(`notification-user-` + window.localStorage.getItem('auth'))
    .listen('GeneralNotificationEvent', (e) => {
        let token = window.localStorage.getItem('authToken') || null;
        if (token != null) {
            if (store.state.home.lastMsgAlert != e.data.title) {
                Vue.$toast.open(e.data.title);
                new Notification("الثورى موتر للمزادات ", {
                    body: e.data.title,
                    icon: "https://api.althawri.com/assets/pic/logo1x.png"
                });

                const audio = new Audio('https://api.althawri.com/notify.wav');
                audio.play();
                store.state.home.lastMsgAlert = e.data.title;
            }
        }
        // console.log(e)
    });
window.Echo.channel(`auction`)
    .listen('AuctionEvent', (e) => {
        // alert(1);
        var element = document.getElementById('auc-card-' + e.data.auction.id);
        // var element = document.querySelector("selector")
        if (element != null) {
            if (e.data.event == 'active') {
                element.classList.add('live');

            } else {
                element.classList.add(e.data.event);

            }
            // console.log(element)

        }
    });
window.Echo.channel(`bid`)
    .listen('BidEvent', (e) => {
        // alert(1);
        var element = document.querySelector('#product-' + e.data.bid.product_id);
        var element2 = document.getElementsByClassName('cur-product-' + e.data.bid.product_id);
        var element3 = document.querySelector('#pproduct-' + e.data.bid.product_id);
        var element4 = document.getElementsByClassName('pro-bids-count-' + e.data.bid.product_id);
        if (element != null)
            element.textContent = (parseInt(e.data.bid.amount) + 500) + ' ر.س';
        if (element3 != null)
            element3.textContent = (parseInt(e.data.bid.amount) + 500) + ' ر.س';
        if (element2 != null) {
            for (var i = 0, max = element2.length; i < max; i++) {
                element2[i].textContent = (parseInt(e.data.bid.amount)) + ' ر.س';
            }

        }
        if (element4 != null) {
            for (var i = 0, max = element4.length; i < max; i++) {
                element4[i].textContent = e.data.bids_count + '';
            }

        }
        // element2.textContent = (parseInt(e.data.bid.amount)) + ' ر.س';
        // win alert
        if (e.data.event == 'win' && e.data.bid.user_id == store.state.profile.profile.id) {
            store.state.home.win_car = 1;
            store.state.home.win_car_id = e.data.bid.product_id;
            // var elem = document.querySelector('#celebration');
            // elem.classList.add('active');
            // elem.style.display = 'unset';
        }
    });
// import "bootstrap/dist/css/bootstrap.min.css"
// or with options
const loadimage = require('./assets/loading.gif')
const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
        preLoad: 1.3,
        error: errorimage,
        loading: loadimage,
        attempt: 1
    })
    // Global Components
import './global-components'
Vue.component('vue-select', Select2);

import vSelect from 'vue-select'

Vue.component('vc-select', vSelect)
    // Vue.use(FlagIcon);
Vue.use(VueApexCharts)
Vue.component(VueCountdown.name, VueCountdown);

Vue.component('apex-chart', VueApexCharts)
Vue.config.productionTip = false

Vue.use(VueTelInput)
new Vue({
    store,
    router,
    axios,
    i18n,
    vuetify,
    render: h => h(App),
}).$mount('#app')