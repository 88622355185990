import axios from "../../axios";

const state = {
    jobsList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://api.althawri.com/',
};

const getters = {
    getjobsList: state => state.jobsList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    setjobsList(state, payload) {
        state.jobsList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addjobs(state, payload) {
        // state.job= payload;
        state.jobsList.push(payload)
    }



};
const actions = {
    fetchjobsList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/jobs`, { params: state.filter })
            .then((response) => {
                commit("setjobsList", response.data.data)
                commit("setLastPage", response.last_page);
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postjob
    postjob({ commit }, job) {

        let formData = new FormData();
        formData.append("titleAr", job.titleAr);
        formData.append("titleEn", job.titleEn);
        formData.append("placeAr", job.placeAr);
        formData.append("placeEn", job.placeEn);
        formData.append("detailsAr", job.detailsAr);
        formData.append("detailsEn", job.detailsEn);
        formData.append("hours", job.hours);
        formData.append("shift", job.shift);
        return axios.post(`api/admin/jobs`, formData).then((response) => {
            console.log("تم اضافة المنتج بنجاح");
            commit("addJobs", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updatejob
    updatejob({ commit }, job) {
        let image = (`https://api.althawri.com/`, job.image); //concat the image url
        let formData = new FormData();
        formData.append("name", job.name);
        formData.append("price", job.price);
        formData.append("image", image);
        formData.append("nameAr", job.nameAr);
        formData.append("category_id", job.category_id);
        console.log("image out ", image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/jobs/${job.id}?_method=PUT`, job).then((response) => {
            commit("setjob", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            console.log("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deletejob({ commit }, id) {
        return axios.delete(`/api/admin/jobs/${id}`).then((response) => {
            commit("deletejob", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSinglejob({ commit }, id) {
        return axios.get(`/api/admin/jobs/${id}`).then((response) => {
            commit("setjob", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "jobs",
    state,
    getters,
    actions,
    mutations,
};