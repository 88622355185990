<template>
    <footer :style="{ 'background-image': `url(${imgb})` }">
		<div class="footer-top">
			<div class="container">
				<div class="row">
					<div class="col-12 col-sm-7 col-lg-4">
						<div class="f-logo">
							<img v-lazy="'https://althawri.com/'+$store.state.home.appInfo.footer_logo" alt="">
						</div>
						<p class="f-text">
							{{$store.state.home.appInfo.footer_about_ar}}
						</p>
						<!-- <div class="btns-wr">
							<a :href="$store.state.home.appInfo.googleplay" target="_blank" class="btn">
								<img src="../../@core/pic/g-play.png" alt="">
							</a>
							<a :href="$store.state.home.appInfo.appstore" target="_blank" class="btn">
								<img src="../../@core/pic/apple-store.png" alt="">
							</a>
						</div> -->
					</div>
					<div class="col-6 col-sm-5 col-lg-auto">
						<div class="f-menu">
							<div class="title">{{ $t('footer.fast_links') }}</div>
							<div class="item">
								<router-link to="/" class="f-link">{{$t('gen.home')}}</router-link>
							</div>
							<div class="item">
								<!-- <router-link to="/auctions" class="f-link">{{ $t('gen.fast_auctions') }}</router-link> -->
							</div>
							<!-- <div class="item">
								<router-link to="/auctions" class="f-link">المزادات طويلة الامد</router-link>
							</div> -->
							<div class="item">
								<router-link to="/filter" class="f-link">{{ $t('footer.cars') }} </router-link>
							</div>
							<div class="item" v-if="$store.state.home.appInfo?.create_auctions_show == '1'">
								<router-link to="/create-auctions" class="f-link" v-if="$store.state.home.htmlLang == 'ar'"> {{$store.state.home.appInfo?.create_auctions_title_ar }} </router-link>
								<router-link to="/create-auctions" class="f-link" v-else> {{$store.state.home.appInfo?.create_auctions_title_en }} </router-link>
							</div>
						</div>
					</div>
					<div class="col-6 col-sm-7 col-lg-auto">
						<div class="f-menu">
							<div class="title">{{ $t('footer.althwri') }}</div>
							<!-- <div class="title">بيع وشراء</div> -->
							<div class="item">
								<router-link to="/about" class="f-link">{{ $t('footer.about') }}</router-link>
							</div>
							<div class="item">
								<router-link to="/contact-us" class="f-link">{{ $t('footer.callus') }}</router-link>
							</div>
							<div class="item">
								<router-link to="/fqe" class="f-link">{{ $t('footer.fqes') }}</router-link>
							</div>
							<div class="item">
								<router-link to="/privacy-policy" class="f-link">{{$t('gen.privacy_policy')}}</router-link>
							</div>
							<div class="item">
								<router-link to="/terms-and-conditions" class="f-link">{{ $t('footer.terms') }}</router-link>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-5 col-lg-auto">
						<div class="f-menu">
							<div class="title">{{ $t('footer.contact') }}</div>
							<div class="item">
								<a :href="'tel://'+$store.state.home.appInfo.mobile" class="f-link">
									<!-- <i class="f-i" data-feather="phone"></i> -->
									<feather-icon
									icon="PhoneIcon" class="f-i"
									/>
									<span>{{$store.state.home.appInfo.mobile}}</span>
								</a>
							</div>
							<div class="item">
								<a :href="'tel://'+$store.state.home.appInfo.phone" class="f-link">
									<!-- <i class="f-i" data-feather="headphones"></i> -->
									<feather-icon
									icon="HeadphonesIcon" class="f-i"
									/>
									<span>{{$store.state.home.appInfo.phone}}</span>
								</a>
							</div>
							<div class="item">
								<span>
									<i class="f-i" data-feather="map-pin"></i>
									<span> {{$store.state.home.appInfo.address_ar}}  </span>
								</span>
							</div>
							<div class="item icons-wr">
								<a :href="$store.state.home.appInfo.youtube" target="_blank"><i class="fab fa-youtube"></i></a>
								<a :href="$store.state.home.appInfo.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
								<a :href="$store.state.home.appInfo.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
								<a :href="$store.state.home.appInfo.snapchat" target="_blank"><i class="fab fa-snapchat"></i></a>
								<a :href="$store.state.home.appInfo.tiktok" target="_blank"><i class="fab fa-tiktok"></i></a>
								<!-- <a :href="$store.state.home.appInfo.linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a>
								<a :href="$store.state.home.appInfo.facebook" target="_blank"><i class="fab fa-facebook-f"></i></a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container">
				<div class="pay">
					<img v-if="$store.state.home.appInfo.paypal == '1'" src="../../@core/pic/Icon awesome-paypal.svg" alt="">
					<img v-if="$store.state.home.appInfo.mada == '1'" src="../../@core/pic/Icon awesome-amazon-pay.svg" alt="">
					<img v-if="$store.state.home.appInfo.applepay == '1'" src="../../@core/pic/Icon awesome-apple-pay.svg" alt=""> 
					<img v-if="$store.state.home.appInfo.visa == '1'" src="../../@core/pic/Icon simple-visa.svg" alt="">
					<img v-if="$store.state.home.appInfo.mastercard == '1'" src="../../@core/pic/Icon payment-mastercard.svg" alt="">
				</div>
				<div class="text">© {{dateY}} {{ $t('footer.rights') }}</div>
			</div>
		</div>
		<div class="fixed-btns" v-if="$route.name != 'car'" id="scrollToTopFixedBtn">
			<a :href="'https://api.whatsapp.com/send?phone=' + $store.state.home.appInfo.phone" target="_blank" class="btn whats">
				<i class="fab fa-whatsapp"></i>
				 <!-- <img src="../../@core/pic/whatsappic.png" alt="" srcset=""> -->
			</a>
			<a :href="'tel:' + $store.state.home.appInfo.phone" class="btn call" v-if="0">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
			</a>
		</div>
<button id="scrollToTopBtn" class="btn">
        <!-- <i data-feather="chevron-up"></i> -->
		<feather-icon
			icon="ChevronUpIcon"
			size="2x"
		/>
    </button>
	</footer>

</template>

<script>
export default {
	data() {
		return {
			appInfo: [],
			dateY: '',
			imgb: "https://api.althawri.com/pic/f-bg.png"
		}
	},
	mounted() {
		if(this.$store.state.home.appInfo.footer_banner !== undefined && this.$store.state.home.appInfo.footer_banner != ''){
			this.imgb = this.$store.state.home.appInfo.footer_banner;
		}
		let date = new Date();
		this.dateY = date.getFullYear();
		this.appInfo = JSON.parse(localStorage.getItem('appInfo'));
		var scrollToTopBtn = document.getElementById("scrollToTopBtn");
		var scrollToTopFixedBtn = document.getElementById("scrollToTopFixedBtn");

			var scrollThreshold = 0.2 * (document.documentElement.scrollHeight - document.documentElement.clientHeight);

			window.onscroll = function () {
				if (document.documentElement.scrollTop > scrollThreshold) {
					scrollToTopBtn.style.display = "block";
					scrollToTopFixedBtn.style.display = "block";
				} else {
					scrollToTopBtn.style.display = "none";
					scrollToTopFixedBtn.style.display = "none";
				}
			};
			scrollToTopBtn.addEventListener("click", function () {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			});
	},
}
</script>
<style scoped>
.fixed-btns {
    position: fixed;
    z-index: 999;
    left: 40px;
    bottom: 10px;
    width: 60px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.fixed-btns .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    font-size: 22px;
    color: #fff;
    border-radius: 50%;
    transition: all 300ms ease;
}


.fixed-btns .btn svg {
    height: 20px;
}

.fixed-btns .btn.whats {
    box-shadow: 0px 8px 18px #25D36633;
    background-color: #25D366;
    margin-bottom: 1rem;
}

.fixed-btns .btn.whats:hover {
    background-color: #FFFFFF;
    color: #25D366 !important;
}

.fixed-btns .btn.call {
    box-shadow: 0px 8px 18px #219BA933;
    background-color: #219BA9;
}

.fixed-btns .btn.call:hover {
    background-color: #FFFFFF;
    color: #219BA9 !important;
}

.fixed-btns {
    right: 40px;
    left: auto;
}
</style>