import axios from "../../axios";

const state = {
    package: [],
    baseUrl: 'https://api.althawri.com/api/v1',
};

const getters = {
    getPackage: state => state.package,
};
const mutations = {
    setPackage(state, payload) {
        state.package = payload;
    },
    setRelated(state, payload) {
        state.related = payload;
    },
};
const actions = {
    fetchPackage({ commit }, item) {
        return axios.get(`/package/${item}`)
            .then((response) => {
                commit("setPackage", response.data.data)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    subscribe({ commit }, payload) {
        // var body = {
        //     product_id: payload.product_id,
        //     amount: payload.amount,
        // }
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/subscribe-package`, payload, { headers }).then((response) => {
            console.log(response)
                // commit("setPackage", response.data.data.package)
            location.replace('/')
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
};

export default {
    namespaced: true,
    name: "package_sub",
    state,
    getters,
    actions,
    mutations,
};