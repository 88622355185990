import Vue from "vue";
import Vuex from "vuex";
// import i18n from '../i18n'
import router from "../../router";
Vue.use(Vuex);
const LANG_KEY = 'locale'
const RTL_KEY = 'rtl'
const initLang = (() => {
    // let lang = window.localStorage.getItem(LANG_KEY) || window.localStorage.setItem(LANG_KEY, lang || 'ar')
    // window.localStorage.setItem('rtl', lang == 'ar')
    // i18n.locale = lang
    // return lang || 'en'
    return 'en'
})()
const initToken = (() => {
    let authToken = window.localStorage.getItem('authToken') || null
    return authToken
})()

export default new Vuex.Store({
    state: {
        lang: initLang,
        authToken: initToken,
        coupon: null,
        loading: false,
        cartCounter: 0,
        user: {
            phone: null
        }
    },
    mutations: {
        onSaveToken(state, payload) {
            window.localStorage.setItem('authToken', payload.authToken);
            state.authToken = payload.authToken
        },
        onSavePhone(state, payload) {
            window.localStorage.setItem('phone', payload.authToken);
            state.user.phone = payload.phone
        },
        onLangChanged(state, payload) {
            window.localStorage.setItem(LANG_KEY, payload.lang)
            window.localStorage.setItem(RTL_KEY, payload.lang === "ar")
            state.lang = payload.lang
            console.log(payload)
            i18n.locale = payload.lang
            vuetify.rtl = payload.lang === "ar",
                location.reload()
        },
        onCartCounterChange(state, payload) {
            state.cartCounter = payload.counter
        },
        onCouponChange(state, payload) {
            state.coupon = payload.coupon
        },
        logout(state) {
            state.authToken = null;
            window.localStorage.removeItem('authToken');
            router.push('/');
        },
    },
    actions: {
        setToken(context, payload) {
            context.commit('onSaveToken', payload)
        },
        setPhone(context, payload) {
            context.commit('onSavePhone', payload)
        },
        changeLanguage(context, payload) {
            context.commit('onLangChanged', payload)
        },
        updateCartCounter(context, payload) {
            context.commit('onCartCounterChange', payload)
        },
        setCoupon(context, payload) {

            context.commit('onCouponChange', payload)
        },
        logout(context) {
            context.commit('logout')
        },
    },
    modules: {},
    getters: {
        // IsAuth(state) {
        //     return state.authToken != null;
        // }
    },
});